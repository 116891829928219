import styled, { css } from 'styled-components';
import Datetime from 'react-datetime';
import { ImageInput } from '../CreateEvent/styles';

import { CustomInput, Button } from '../LoginPage/styles';

const BoldFont = 'Rubik-Bold';

const Layout = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  background:#f3f6f9
  @media (max-width: 600px) {
  max-width: 100%;

  }
`;

const Title=styled.h1`
display:none;
@media (max-width: 600px) {
  display:block;
  position:static;
  top:125px;
  margin:25px 0;
  width: 100%;
  font-family: 'Rubik-medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: #0D4270;

  }

`
const FormContainer = styled.div`
width: 100%;
max-width:1246px;
  
  float: left;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 25px;
  margin: 0 20px;
  margin: 0px 0 100px 0;

  .register {
    .dropdown {
      width: calc(48% - 40px);
    }
    
    .dropdown-menu {
      width: 100%;
      max-height: 300px;
      overflow: hidden;
      overflow-y: auto;
    }
    @media (max-width: 767px) {
      .dropdown {
        width: calc(80% - 40px);
      }
    }
    @media (max-width: 600px) {
     
      .dropdown {
        width: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    width:100vw !important;
  margin:0;

  padding:16px;
  padding-bottom:0;


  }
`;
const HeaderBox=styled.div`
 width:100%;
 height:94px;
 border-bottom: 1px solid rgba(156, 156, 156, 0.4);
 text-align:left;
h1{
  font-family: 'Rubik-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  align-items: center;
  color: #69C2FF;
}
h3{
  font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 15px;
color: #9C9C9C;
}
@media (max-width: 600px) {
height:75px;
border:none;
display:${({ none }) => none &&"none"};
 h1{display:none}
 
}
`

const TitleBlock = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-align: center;
  text-transform: uppercase;
  
  > span {
    color: #159fc9;
  }
`;

const InformationText = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  text-align: center;
`;

const Step = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background-color: ${({active}) => active ? '#69C2FF' : '#9C9C9C 10%'};
  display: inline-block;
  color: ${({active}) => active ? 'rgb(255,255,255)' : '#9C9C9C'};
  border: none;
  font-size: 24px;
  letter-spacing: 0px;
  font-family: ${BoldFont};
  text-align: center;
  cursor: pointer;
  padding: 0;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
  `}
  
  &:active, &:focus {
    outline: none;
  }
`;


const StepBox=styled.button`
display: flex;
justify-content:center;
align-items:center;
width:150px;
flex-direction:column;
border:none;
background:transparent;
h1{
  cursor: pointer;

font-family:  ${({active}) => active ?'Rubik-medium': 'Rubik'}
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #0D4270;
${({ disabled }) => disabled && css`
cursor: not-allowed !important;
`}

&:active, &:focus {
outline: none;
}
}
div{
width: 50px;
  height: 50px;
  border-radius: 3px;
  display: flex;
justify-content:center;
align-items:center;
  background-color: ${({active}) => active ? '#69C2FF' : 'rgba(156, 156, 156, 0.1)'};
  color: ${({active}) => active ? 'rgb(255,255,255)' : '#9C9C9C'};
  border: none;
  font-size: 24px;
  letter-spacing: 0px;
  font-family: ${BoldFont};
  text-align: center;
  cursor: pointer;
  padding: 0;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
  `}
  
  &:active, &:focus {
    outline: none;
  }
}

@media (max-width: 600px) {
 h1{
  display:none;
 }
 div{
  width:40px;
  height:40px;
 }
}
@media (max-width: 280px) {

div{
  font-size:20px;
  width:35px;
  height:35px;
 }
}
`

const ConnectingLine = styled.div`
  display: inline-block;
  
  margin-bottom:45px;
  @media (max-width: 600px) {
display:none;
    margin-bottom:0;
  }

`;

const ConnectingLine2 = styled.div`
display:none;
  

  @media (max-width: 600px) {
    display: inline-block;

    margin-bottom:0;
  }

`;

const StepsContainer = styled.div`
  width: 100%;
  max-width:1246px;
 top:85px;
 display: flex;
 margin-top:80px;
justify-content:center;
align-items:center;
 padding:34px 0px 23px 0px;
 height:140px;
  background-color:white;
  z-index:2;
  border-bottom: 1px solid rgba(156, 156, 156, 0.4);
@media (max-width: 600px) {
margin:0;
  background-color:#f3f6f9;
  border-bottom:1px solid #f3f6f9;
  height:auto;
 margin-top:85px;
  padding:0px 0 0 0;


}

`;

const FormSection = styled.div`
  width: ${({lightboxPopup, isRegistrationForm}) => lightboxPopup ? '100%' : (isRegistrationForm) ? '100%':'100%'};
  padding: ${({setWidth}) => setWidth ? '0px' : '0px 0px 0px 0px '};
  display:flex;
  justofy-content:space-between;
  margin-top:${({isRegistrationForm})=>isRegistrationForm? "25px":"0"};
  align-items:center;
  flex-wrap:wrap;
  .dropdown {
    width: ${({ setWidth }) => setWidth && '100%'};
  }
  .dropdown-menu {
    width: ${({ setWidth }) => setWidth && '100%'};
    max-height: 200px;
    overflow-y: auto;
    
  }


  
  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
    flex-direct:column;
    padding: 0;
    border-bottom:none;
  }
`;

const StyledInput = styled(CustomInput)`
  padding: 15px;


`;

const StyledDateTime = styled(Datetime)`
width:100%;
max-width:585px;
  > input {
    padding: 15px;
    width:100%;
    max-width:585px;
    border-radius:6px;
    font-size: 18px;
    height: 60px;
    color: rgb(153,153,153);
    border: 1px solid #cdcbcb;
    position: relative;
    background-color: white !important;
    cursor: pointer;
    font-family:rubik-medium;
    color: rgba(0,47,71,1);
    &:hover, &:focus, &:active {
      outline: none;
      border-color: #cdcbcb;
      box-shadow: none;
     
    }
  }
  > input::placeholder{
font-family:rubik;
  }
  
  .rdtPicker {
    width: 100%;
  }
`;

const NextButton =styled(Button)`
  margin: 0px;
  width: 200px;
  height:50px;
  font-family: 'Rubik';
  margin:8px 0;

font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
background: #69C2FF;
border-radius: 3px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;

color: #FFFFFF;
  
  > i {
    margin-left: 10px;
    font-size: 12px;
  }
  @media (max-width: 600px) {
    width: 90%;
  
  }
`;

const DownArrow = styled.span`
 
  float: left;
  position: absolute;
  top: ${(isRegistrationStep1) => isRegistrationStep1 ? '25px' : '44px'};
  right: 5%;
  cursor: pointer;
  display: ${(isRegistrationStep1) => isRegistrationStep1 && 'flex'};
  justify-content: ${(isRegistrationStep1) => isRegistrationStep1 && 'right'};
  align-items: ${(isRegistrationStep1) => isRegistrationStep1 && 'center'};
  width: ${(isRegistrationStep1) => isRegistrationStep1 && '100%'};
  height: ${(isRegistrationStep1) => isRegistrationStep1 && '50px'}
  img{
    width: ${(isRegistrationStep1) => isRegistrationStep1 && '24px'};
    height: ${(isRegistrationStep1) => isRegistrationStep1 && '22px'};
  }
`;  

const ButtonContainer = styled.div`

  width: 100%;
  background:white;
  display: flex;
  justify-content:flex-end;
align-items: center;
text-align: center;
padding-top:17px;
border-top:1px solid rgba(156,156,156,0.6);
// box-shadow: 13px -5px 14px rgba(0, 0, 0, 0.1);

@media (max-width: 600px) {
  width: calc(100% + 32px);
  margin: 0;
  margin-left: -16px;
  justify-content:center; 
  position:unset;
  flex-direct:column;
  padding:25px 0;
  border-top:none;
  box-shadow:unset;
  background: #f3f6f9;

}
`;

const ErrorMessageContainer = styled.div`
  width: calc(100% - 40px);
  max-width:1246px;
  background-color: #efe3e3;
  margin: 0 auto;
  margin-bottom: 5px 0;
  height: 50px;
 
  top:230px;
  > li {
    line-height: 50px;
    padding-left: 20px;
    font-size: 15px;
  }
 

  @media (max-width: 600px){
    margin-bottom: 21px;
   position: static;
    z-index: 3;
  }
  
`;

const RadioButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top:25px;
  p{
    margin-right:110px;
    font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;

color: #20495E;
     
  }
  @media (max-width: 600px) {
    width:100%;
     flex-direction: column;
     justify-content:center;
     margin:0;
     p{text-align:center;
     justify-content:center;
width:100%;
    margin:0;
    margin-bottom:15px
  }
    >div{
display:flex;

width:100%;
justify-content:space-evenly;

    }

  }
`;
const UploadPhotoContainer=styled.div`
width:100%
height:150px;
display: flex;
  justify-content: flex-start;
  align-items:center;
  margin-top:15px;
   .innerDivImageContainer{
width:100%;
    height:100%;
   position:relative;
   display: flex;
  justify-content: flex-start;
  align-items:center;
   }

   .innerDivImageContainer img{
  width:150px;
  height:150px;
  object-fit:cover;
border-radius:3px;
border:1px dashed #9C9C9C

}

.innerDivImageContainer  .infoBtn{
width:24px;
heigth:24px;
font-size:15px;
color:#fff;
border-radius:50%;
background-color:#9C9C9C}

@media (max-width: 600px) {
  width:100%;
   flex-direction: column;
   justify-content:center;
   .innerDivImageContainer {
    width:125px;
  height:125px;
  border-radius:50%;
  overflow:hidden;
   }
   .innerDivImageContainer  .infoBtn{display:none}
   .innerDivImageContainer  img{
  width:125px;
  height:125px;
  border-radius:50%
}
}
`

const InputImage = styled(ImageInput)`
display: flex;
justify-content: center;
align-items:center;
width:164px;
height:50px;
background-color:#69C2FF;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
  padding: 0;
  margin-bottom: 0;
  > i {
font-family:rubik-medium;
font-style:normal;
font-weight:400;
font-size:18px;
color:#fff; 
text-transform: capitalize;
  }
  @media (max-width: 600px) {
    position: absolute;
background-color:#c4c4c4;
margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;
    >i{
      font-size:10px;
    }
  }
`;

const RemoveImage= styled.div`
display: flex;
justify-content: center;
color:white;
align-items:center;
width:164px;
height:50px;
background-color:#FD7175;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer
padding: 0;
margin-bottom: 0;
font-family:rubik-medium;
@media (max-width: 600px) {
  position: absolute;
background-color:#c4c4c4;

margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
  font-size: 10px;
  >i{
    font-size:10px;
  }
}

`
const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: #20495E;
  line-height: 21px;
  font-family: Rubik;
  font-weight: 400;
  position: relative;
  pointer-events: none;
  >span{
    font-size: 18px;
    color:#F6797D;
  }
`;

const StyledInputV2 = styled.input`
  border: none;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 18px;
  margin-bottom: 0;
  height: 60px;
  font-family: rubik-medium;
  font-weight:500;
  width: 100%;
  max-width:585px
  border-radius:6px;
  color: rgba(0, 47, 71, 1);
  border: 1px solid #cdcbcb;
  background-color: white !important;
  &:active{
    border: 1px solid #9c9c9c;
  }
  &::placeholder {
    font-size: 18px;
    color: #9c9c9c;
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
  }
  
`;

const InputContainer = styled.div`
  width:49%;
  max-width:585px;
  display: flex;
  justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  margin: auto;
  padding-bottom: 25px;
  .PhoneInput{
    margin-left: -35px;
      .PhoneInputInput{
        padding: 12px;
      }
  }
  .dropdown{
  >option {
  
  .open{
  &::placeholder {
    font-size: 18px;
    color: #9c9c9c;
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
   
  } } @media (max-width: 1200px) {
    width: 100%;
  }
  }}

  @media (max-width: 600px) {
    width: 100%;
    
  }


  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;











export { Layout,ConnectingLine2, FormContainer,InputContainer, TitleBlock,StyledInputV2,StyledLabel, InformationText, Step, ConnectingLine, StepsContainer,StepBox, FormSection, StyledInput, StyledDateTime, NextButton,
  DownArrow, ButtonContainer, ErrorMessageContainer,Title, RadioButtonContainer,HeaderBox,UploadPhotoContainer,InputImage,RemoveImage };
